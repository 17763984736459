var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-general md:ml-4 md:mt-0 mt-4 ml-0"},[_c('vx-card',[_c('h4',{staticClass:"mb-4"},[_vm._v("ตั้งค่าระบบถอน")]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/5 w-full mt-2"},[_c('label',{staticClass:"mb-1"},[_vm._v("เวลาที่ลูกค้าถอนได้ต่อครั้ง")]),_c('vx-input-group',{staticClass:"mb-base"},[_c('vs-input',{attrs:{"disabled":_vm.$store.state.AppActiveUser.permissions.setting.action
                ? false
                : true,"type":"number","min":"15","max":"60","placeholder":"ใส่จำนวนนาที"},model:{value:(_vm.min),callback:function ($$v) {_vm.min=$$v},expression:"min"}}),_c('template',{slot:"append"},[_c('div',{staticClass:"append-text bg-primary"},[_c('span',[_vm._v("นาที")])])])],2)],1),_c('div',{staticClass:"vx-col md:w-1/5 w-full mt-2"},[_c('label',{staticClass:"mb-1"},[_vm._v("จำนวนพนักงานถอนเงิน ")]),_c('vx-input-group',{staticClass:"mb-base"},[_c('vs-input',{attrs:{"disabled":_vm.$store.state.AppActiveUser.permissions.setting.action
                ? false
                : true,"type":"number","min":"1","max":"10","placeholder":"ใส่จำนวนพนักงาน"},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('template',{slot:"append"},[_c('div',{staticClass:"append-text bg-primary"},[_c('span',[_vm._v("คน")])])])],2)],1),_c('div',{staticClass:"vx-col w-full mt-2"},[_c('h4',[_vm._v("เปิด/ปิด ถอน")]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 w-full mt-3"},[(_vm.scb_bank.length > 0)?_c('p',{staticStyle:{"color":"white"}},[_vm._v("\n              ถอน SCB ผ่านเว็บ\n            ")]):_vm._e()]),_c('div',{staticClass:"vx-col md:w-1/2 w-full mt-2"},[(_vm.scb_bank.length > 0)?_c('vs-switch',{attrs:{"disabled":_vm.$store.state.AppActiveUser.permissions.setting.action
                  ? false
                  : true},on:{"click":function($event){return _vm.update_bankshow(_vm.scb_bank[0].bank_status_web, 'SCB_WEB')}},model:{value:(_vm.scb_bank[0].bank_status_web),callback:function ($$v) {_vm.$set(_vm.scb_bank[0], "bank_status_web", $$v)},expression:"scb_bank[0].bank_status_web"}},[_c('span',{attrs:{"slot":"on"},slot:"on"},[_vm._v("On")]),_c('span',{attrs:{"slot":"off"},slot:"off"},[_vm._v("Off")])]):_vm._e()],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mt-3"},[(_vm.scb_bank.length > 0)?_c('p',{staticStyle:{"color":"white"}},[_vm._v("\n              ถอน SCB ผ่านแอพ\n            ")]):_vm._e()]),_c('div',{staticClass:"vx-col md:w-1/2 w-full mt-2"},[(_vm.scb_bank.length > 0)?_c('vs-switch',{attrs:{"disabled":_vm.$store.state.AppActiveUser.permissions.setting.action
                  ? false
                  : true},on:{"click":function($event){return _vm.update_bankshow(_vm.scb_bank[0].bank_status_app, 'SCB_APP')}},model:{value:(_vm.scb_bank[0].bank_status_app),callback:function ($$v) {_vm.$set(_vm.scb_bank[0], "bank_status_app", $$v)},expression:"scb_bank[0].bank_status_app"}},[_c('span',{attrs:{"slot":"on"},slot:"on"},[_vm._v("On")]),_c('span',{attrs:{"slot":"off"},slot:"off"},[_vm._v("Off")])]):_vm._e()],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mt-3"},[(_vm.ktb_bank.length > 0)?_c('p',{staticStyle:{"color":"white"}},[_vm._v("ถอน KTB")]):_vm._e()]),_c('div',{staticClass:"vx-col md:w-1/2 w-full mt-3"},[(_vm.ktb_bank.length > 0)?_c('vs-switch',{attrs:{"disabled":_vm.$store.state.AppActiveUser.permissions.setting.action
                  ? false
                  : true},on:{"click":function($event){return _vm.update_bankshow(_vm.ktb_bank[0].bank_status, 'KTB')}},model:{value:(_vm.ktb_bank[0].bank_status),callback:function ($$v) {_vm.$set(_vm.ktb_bank[0], "bank_status", $$v)},expression:"ktb_bank[0].bank_status"}},[_c('span',{attrs:{"slot":"on"},slot:"on"},[_vm._v("On")]),_c('span',{attrs:{"slot":"off"},slot:"off"},[_vm._v("Off")])]):_vm._e()],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mt-3"},[(_vm.bay_bank.length > 0)?_c('p',{staticStyle:{"color":"white"}},[_vm._v("ถอน BAY")]):_vm._e()]),_c('div',{staticClass:"vx-col md:w-1/2 w-full mt-3"},[(_vm.bay_bank.length > 0)?_c('vs-switch',{attrs:{"disabled":_vm.$store.state.AppActiveUser.permissions.setting.action
                  ? false
                  : true},on:{"click":function($event){return _vm.update_bankshow(_vm.bay_bank[0].bank_status, 'BAY')}},model:{value:(_vm.bay_bank[0].bank_status),callback:function ($$v) {_vm.$set(_vm.bay_bank[0], "bank_status", $$v)},expression:"bay_bank[0].bank_status"}},[_c('span',{attrs:{"slot":"on"},slot:"on"},[_vm._v("On")]),_c('span',{attrs:{"slot":"off"},slot:"off"},[_vm._v("Off")])]):_vm._e()],1)])])]),_c('vs-button',{staticClass:"my-4",attrs:{"disabled":_vm.$store.state.AppActiveUser.permissions.bank.action ? false : true,"color":"primary"},on:{"click":function($event){return _vm.update_status_user()}}},[_vm._v("ยืนยัน")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }